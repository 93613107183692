import {Directive} from '@angular/core';
import {NgControl} from '@angular/forms';

import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';

@Directive({
  standalone: true,
  selector: '[phonenumber]'
})
export class PhonenumberDirective {

  constructor(ngControl: NgControl) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const valueAccessor = ngControl.valueAccessor!;
    const {registerOnChange} = valueAccessor;

    valueAccessor.registerOnChange = (fn: any) =>
      registerOnChange.call(valueAccessor, (value: any) => {
        try {
          const number = phoneUtil.parse(value, 'DE');
          const newValue = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL)
          if (newValue !== value) {
            ngControl.control?.patchValue(newValue, {emitModelToViewChange: true});
          }
          value = newValue;
        } catch (ignored) {
        }
        return fn(value);
      });
  }
}
