import {Pipe, PipeTransform} from '@angular/core';
import {ChainDocument, ReportDocument} from '../../model';

@Pipe({name: 'mapSourceName', standalone: true})
export class MapSourceNamePipe implements PipeTransform {
  transform(value: ReportDocument | ChainDocument): string {
    switch (value.source) {
      case 'chain':
        return 'SupplyChain';
      case 'product':
        return 'Product';
      case 'siteManual':
        return 'Company Site(Manual)';
      case 'site':
        return 'Company Site';
      case 'company':
        return 'Company';
      case 'report':
        return 'Report';
    }
  }
}
