import {AfterViewInit, Directive, ElementRef, inject, Renderer2} from '@angular/core';

@Directive({standalone: true, selector: '[overflowGradient]'})
export class OverflowGradientDirective implements AfterViewInit {
  private renderer = inject(Renderer2);
  private el = inject(ElementRef).nativeElement;

  ngAfterViewInit(): void {
    if (this.el.scrollHeight > this.el.clientHeight) {
      this.renderer.setStyle(this.el, 'maskImage', 'linear-gradient(to bottom, black 25%, transparent)');
    }
  }
}
