import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import * as mapboxgl from 'mapbox-gl';
import {environment} from '../../../environments/environment';
import {HeaderActionsComponent} from '../../common';

@Component({
  selector: 'app-map-select-dialog',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, HeaderActionsComponent],
  templateUrl: 'map-select-dialog.component.html'
})
export class MapSelectDialogComponent implements OnInit {
  @ViewChild('mapContainer', {static: true}) mapContainer!: ElementRef;
  map!: mapboxgl.Map;
  marker!: mapboxgl.Marker;

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.marker = new mapboxgl.Marker({
      draggable: true
    })
      .setLngLat([data.lng, data.lat]);
  }

  ngOnInit() {
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: this.mapContainer.nativeElement,
      style: 'mapbox://styles/mapbox/satellite-streets-v12',
      zoom: 5,
      center: this.marker.getLngLat(),
    })
      .addControl(new mapboxgl.NavigationControl())
      .on('load', () => this.map.resize());
    this.marker.addTo(this.map);
  }
}
