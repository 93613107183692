import {HttpClient} from '@angular/common/http';
import {Params, Router} from '@angular/router';
import {AppId, HasId, Page} from '../../model';
import {buildQuery} from '../../util';
import {inject} from '@angular/core';
import {firstValueFrom} from 'rxjs';

const url = (...segment: string[]) => segment.join('/');

/* tslint:disable:class-name */
export class _Service<T extends HasId> {
  protected http = inject(HttpClient);
  private router = inject(Router);

  constructor(protected prefix: string) {
  }

  mapId = (f: AppId): string => (f as any);
  list = async (queryParams: Params) => firstValueFrom(this.http.get<Page<T>>(this.prefix + buildQuery(queryParams)));
  detail = async (id: AppId) => firstValueFrom(this.http.get<T>(url(this.prefix, this.mapId(id))));
  update = async (id: AppId, data: T) => firstValueFrom(this.http.put<T>(url(this.prefix, this.mapId(id)), {...data}));
  create = async (data: T) => firstValueFrom(this.http.post<T>(this.prefix, {...data}));
  delete = async (data: T) => firstValueFrom(this.http.delete<T>(url(this.prefix, this.mapId(data.id))));
  listLimit = async (params: Params, pageVar: string) => {
    const list = await this.list(params);
    if (+params[pageVar] >= list.totalPages) {
      const queryParams = {...params};
      queryParams[pageVar] = (list.totalPages > 1) ? list.totalPages - 1 : undefined;
      await this.router.navigate([], {queryParams});
    }
    return list;
  };
}
