export function buildQuery(query: { [key: string]: any }): string {
  const keys = query ? Object.keys(query).filter(key => query[key] != null) : [];

  if (keys.length > 0) {
    return '?' + keys.map(key => key + '=' + encodeURIComponent(query[key])).join('&');
  }
  return '';
}

export const extractFilenameFromHeader = (headerValue: string) =>
  headerValue.split(';')
    .map(e => e.trim())
    .map(e => e.split('=', 2))
    .filter(([k, _]) => k === 'filename')
    .map(([_, v]) => v.replace(/^['"]|['"]$/g, ''))[0];

const pad10 = (n: number) => n < 10 ? ('0' + n) : n;
const pad100 = (n: number) => n < 10 ? ('00' + n) : n < 100 ? ('0' + n) : n;
const pad1000 = (n: number) => n < 10 ? ('000' + n) : n < 100 ? ('00' + n) : n < 1000 ? ('0' + n) : n;

export const formatJsonDate = (date: Date | string | undefined | null): string | undefined => !date ? undefined :
  typeof date === 'string' ? formatJsonDate(new Date(date))
    : pad1000(date.getFullYear()) + '-' + pad10(date.getMonth() + 1) + '-' + pad10(date.getDate());

export const formatJsonTime = (date: Date | string | undefined | null): string | undefined => !date ? undefined :
  typeof date === 'string' ? formatJsonTime(new Date(date))
    : pad10(date.getHours()) + ':' + pad10(date.getMinutes()) + ':' + pad10(date.getSeconds()) + '.' +
    pad100(date.getMilliseconds());

export const formatJsonDateTime = (date: Date | string | undefined | null): string | undefined => !date ? undefined :
  typeof date === 'string' ? formatJsonDateTime(new Date(date))
    : formatJsonDate(date) + 'T' + formatJsonTime(date);
