<mat-toolbar mat-dialog-title>
  Create Supply Chain
</mat-toolbar>

<mat-dialog-content style="padding: 2em 1em">
  <form [formGroup]="form">
    <div>
      Define a group or a single sales-product, that share the same suppliers and input-materials:
    </div>

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input autotrim formControlName="name" matInput>
    </mat-form-field>

    <mat-form-field>
      <mat-label>HS-Code</mat-label>
      <input autotrim formControlName="hsCode" matInput>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Notes</mat-label>
      <textarea autotrim formControlName="notes" matInput
                placeholder="e.g. description, internal article numbers"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<dialog-form-actions>
  <button mat-button mat-dialog-close>cancel</button>
  <button [disabled]="form.invalid || form.pristine" [mat-dialog-close]="form.value" color="primary" mat-raised-button>
    <mat-icon>add</mat-icon>
    create Supply Chain
  </button>
</dialog-form-actions>
