import {Component, ElementRef, Input, OnDestroy, Optional, Self} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ControlValueAccessor, FormArray, FormControl, NgControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgForOf} from '@angular/common';
import {Subject} from 'rxjs';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {HasIdName} from '../model';

@Component({
  selector: 'checkbox-selection[values]',
  standalone: true,
  template: `
    <mat-checkbox (input)="onChange(value)"
                  *ngFor="let control of controls.controls, let i=index"
                  [formControl]="control"
                  [value]="values[i].id">
      {{ values[i].name }}
    </mat-checkbox>
  `,
  providers: [{provide: MatFormFieldControl, useExisting: CheckboxSelectionInputComponent}],
  host: {
    '[id]': 'id',
    '(focusin)': 'onFocusIn()',
    '(focusout)': 'onFocusOut($event) //noinspection UnresolvedReference',
    'role': 'group'
  },
  imports: [NgForOf, ReactiveFormsModule, MatCheckboxModule],
})
export class CheckboxSelectionInputComponent implements ControlValueAccessor, MatFormFieldControl<HasIdName[]>, OnDestroy {
  static nextId = 0;
  controls = new FormArray<FormControl<boolean | null>>([]);
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'checkbox-selection';
  id = `checkbox-selection-${CheckboxSelectionInputComponent.nextId++}`;
  shouldLabelFloat = true;

  constructor(private _elementRef: ElementRef<HTMLElement>, @Optional() @Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  private _values: HasIdName[] = [];

  get values() {
    return this._values;
  }

  @Input()
  set values(values: HasIdName[]) {
    this._values = values;
    this.controls = new FormArray(values.map(() => new FormControl(false)));
    this.stateChanges.next();
  }

  get empty() {
    return !!this.controls.value.length;
  }

  private _placeholder!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required || !!this.ngControl.control?.hasValidator(Validators.required);
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.controls.disable() : this.controls.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): HasIdName[] | null {
    return this.controls.valid
      ? this.controls.value.flatMap((selected, index) =>
        selected ? [this.values[index]] : [])
      : null;
  }

  set value(value: HasIdName[] | null) {
    const ids = (value || []).map(({id}) => id);
    this.controls.setValue(this.values.map(({id}) => ids.includes(id)));
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return (this.controls.invalid || !!this.ngControl.control?.invalid) && this.touched;
  }

  setDescribedByIds = () => void 0;
  onContainerClick = () => void 0;
  onChange = (value: HasIdName[] | null) => void 0;
  onTouched = () => void 0;
  registerOnChange = (fn: any): void => this.onChange = fn;
  registerOnTouched = (fn: any): void => this.onTouched = fn;

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  writeValue(selection: HasIdName[] | null): void {
    this.value = selection;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
