import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {SiteType} from '../../model';

@Injectable({providedIn: 'root'})
export class SiteTypeService extends _Service<SiteType> {
  constructor() {
    super('api/site-type');
  }

  listAll = () => this.http.get<SiteType[]>(this.prefix);
}
