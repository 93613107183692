import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {AfterViewInit, Component, ElementRef, HostListener, inject} from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {DialogFormActionsComponent} from './dialog-form-actions.component';
import {Dialog} from './dialog.service';

export interface FormComponent {
  hasUnsavedChanges(): boolean;
}

export const preventUnsavedChangesGuard = async (component: FormComponent) => {
  const dialog = inject(Dialog);
  return component.hasUnsavedChanges && component.hasUnsavedChanges()
    ? await dialog.open(HasUnsavedChangesDialogComponent,
      {width: '100vw', maxWidth: '600px', disableClose: true,})
    : true;
};

@Component({
  templateUrl: 'prevent-unsaved-changes-guard.service.html',
  standalone: true,
  imports: [MatDialogModule, MatDividerModule, MatButtonModule, MatToolbarModule, DialogFormActionsComponent],
})
export class HasUnsavedChangesDialogComponent implements AfterViewInit {
  private dialogRef = inject(MatDialogRef);
  private elRef = inject(ElementRef);
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownEscape = () => this.dialogRef.close(false);

  ngAfterViewInit(): void {
    let parentElement = this.elRef.nativeElement;
    let abort = 0;
    while (parentElement.parentNode.tagName.toLowerCase() !== 'body') {
      parentElement = parentElement.parentNode;
      if (abort++ > 10) {
        console.log('unable to patch parent class');
        return;
      }
    }
    parentElement.setAttribute('class', 'cdk-overlay-container changes-confirm-overlay-hack');
  }
}
