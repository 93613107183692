import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {
  AppId,
  Company,
  CompanySite,
  Document,
  HasId,
  HasIdName,
  Page,
  Product,
  ProductChain,
  Report,
  SupplierProduct
} from '../../model';
import {buildQuery} from '../../util';
import {Params} from '@angular/router';
import {tap} from 'rxjs/operators';
import {AppState} from '../app-state';
import {firstValueFrom} from 'rxjs';

export interface StripeSubscription {
  id: string;
  status: string;
  price: string;
  cancelAt?: Date;
}

@Injectable({providedIn: 'root'})
export class CompanyService extends _Service<Company> {
  constructor(private appState: AppState) {
    super('api/company');
  }

  override list = async (queryParams: Params) =>
    firstValueFrom(this.http.get<Page<Company>>('api/admin/company' + buildQuery(queryParams)));
  reports = async (id: string, queryParams: Params) =>
    firstValueFrom(this.http.get<Page<Report>>(`api/company/${id}/report` + buildQuery(queryParams)));
  supplier = async (id: string, queryParams: Params) =>
    firstValueFrom(this.http.get<Page<SupplierProduct>>(`api/company/${id}/supplier` + buildQuery(queryParams)));
  addSupplier = async (product: Product, chainId: string) =>
    firstValueFrom(this.http.post<Product>(`api/product/${product.id}/supplier`, {chainId}));
  documentListForCompany = async (companyId: AppId, queryParams: Params) =>
    firstValueFrom(this.http.get<Page<Document>>(`api/company/${companyId}/document` + buildQuery(queryParams)));
  documentListForSite = async (siteId: AppId, queryParams: Params) =>
    firstValueFrom(this.http.get<Page<Document>>(`api/site/${siteId}/document` + buildQuery(queryParams)));
  productListForSite = async (id: string, queryParams: Params) =>
    firstValueFrom(this.http.get<Page<Product>>(`api/site/${id}/product` + buildQuery(queryParams)));
  productAllForSite = async (id: string) =>
    firstValueFrom(this.http.get<Product>(`api/site/${id}/product/all`));
  product = async (productId: string) =>
    firstValueFrom(this.http.get<Product>(`api/product/${productId}`));
  chain = async (productId: string) =>
    firstValueFrom(this.http.get<ProductChain[]>(`api/product/${productId}/chain`));
  products = async (id: string) =>
    firstValueFrom(this.http.get<Page<Product>>(`api/company/${id}/product/all`));
  byIds = async (id: string[]) =>
    firstValueFrom(this.http.post<HasIdName[]>(`api/company/byId`, id));
  findCompaniesByName = async (q: string) =>
    firstValueFrom(this.http.get<HasIdName[]>(`api/company/find?q=${encodeURIComponent(q)}`));
  listAll = async () =>
    firstValueFrom(this.http.get<Company[]>('api/company/all')
      .pipe(tap((companies) => this.appState.setCompanies(companies))));
  addProduct = async (site: CompanySite, product: Product) =>
    firstValueFrom(this.http.post<Product>(`api/site/${site.id}/product`, product));
  updateProduct = async (id: AppId, data: { product: Product }) =>
    firstValueFrom(this.http.put<Product>(`api/product/${id}`, data));
  deleteChain = async (chain: ProductChain) =>
    firstValueFrom(this.http.delete<any>(`api/chain/${chain.id}`));
  createSite = async (company: Company, site: CompanySite) =>
    firstValueFrom(this.http.post<HasIdName>(`api/company/${company.id}/site`, site));
  updateSite = async (id: AppId, site: CompanySite) =>
    firstValueFrom(this.http.put<HasIdName>(`api/site/${id}`, site));
  stripeLink = async (companyId: AppId, returnUrl: string) =>
    firstValueFrom(this.http.post<{ link: string }>(`api/company/${companyId}/stripe`, {returnUrl}));
  stripePay = async (companyId: AppId, plan: string, returnUrl: string) =>
    firstValueFrom(this.http.post<{ link: string }>(`api/company/${companyId}/stripe/create-subscription`,
      {plan, returnUrl}));
  stripeSubs = async (companyId: AppId) =>
    firstValueFrom(this.http.get<StripeSubscription[]>(`api/company/${companyId}/stripe/subscription`));
  updateOwner = async (companyId: AppId, owner: HasId) =>
    firstValueFrom(this.http.put<void>(`api/company/${companyId}/owner`, owner));
}
