import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Component, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {DialogFormActionsComponent} from '../../common';
import {MatSelectModule} from '@angular/material/select';
import {Company, User} from '../../model';
import {MatIconModule} from '@angular/material/icon';

@Component({
  standalone: true,
  templateUrl: 'select-owner-dialog.component.html',
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule, MatDialogModule, DialogFormActionsComponent, MatToolbarModule,
    MatButtonModule, MatIconModule, MatSelectModule,
  ]
})
export class SelectOwnerDialogComponent {
  users: User[];
  company: Company;
  form = new FormBuilder().nonNullable.group({
    owner: ['', Validators.required],
  });

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.users = data.users;
    this.company = data.company;
    this.form.patchValue({owner: data.company.owner.id});
  }
}
