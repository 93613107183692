import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {DocumentType} from '../../model';
import {buildQuery} from '../../util';
import {firstValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DocumentTypeService extends _Service<DocumentType> {
  constructor() {
    super('api/document-type');
  }

  listAll = async (category?: 'company' | 'chain' | 'report') =>
    firstValueFrom(this.http.get<DocumentType[]>(this.prefix + buildQuery({category})));
}
