<mat-toolbar mat-dialog-title>
  Confirm Delete
</mat-toolbar>

<mat-dialog-content style="padding:2em 1em">
  Continue delete {{ data.type }} <strong>{{ data.name }}</strong>?
</mat-dialog-content>

<dialog-form-actions>
  <button [mat-dialog-close]="true" color="warn" mat-raised-button>Delete</button>
  <button [mat-dialog-close]="false" mat-raised-button>cancel</button>
</dialog-form-actions>
