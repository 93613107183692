<mat-toolbar mat-dialog-title>
  {{ title }}
</mat-toolbar>

<mat-dialog-content style="padding: 0.5em 1em">
  <form [formGroup]="form">
    <mat-radio-group *ngIf="step == 1" formControlName="type">
      <div (click)="type=t.id" *ngFor="let t of types" class="type">
        <mat-radio-button [value]="t.id">
          {{ t.name }}
        </mat-radio-button>
      </div>
    </mat-radio-group>

    <mat-form-field *ngIf="step == 2">
      <mat-label>Document Date</mat-label>
      <input [matDatepicker]="documentDatePicker" formControlName="date" matInput
             placeholder="MM/DD/YYYY">
      <mat-datepicker-toggle [for]="documentDatePicker" matIconSuffix></mat-datepicker-toggle>
      <mat-datepicker #documentDatePicker></mat-datepicker>
      <mat-error>Required field</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="step == 2">
      <mat-label>Title</mat-label>
      <input autotrim formControlName="title" matInput>
      <mat-error>Required field</mat-error>
    </mat-form-field>

    <div *ngIf="step == 2 && uploadStatus">
      <h4 mat-line>{{ uploadStatus.name }}</h4>

      <div *ngIf="uploadStatus.error; else uploading">
        <mat-error>{{ uploadStatus.error }}</mat-error>
        <button (click)="removeUpload()" color="primary" mat-raised-button>ok</button>
      </div>
      <ng-template #uploading>
        <mat-label>Uploading ... {{ uploadStatus.progress | number:'1.0-0' }}%</mat-label>
        <mat-progress-bar [value]="uploadStatus.progress" mode="determinate"></mat-progress-bar>
        <button (click)="removeUpload()" mat-button>Cancel</button>
      </ng-template>
    </div>

    <div *ngIf="step == 2 && uploadResult" style="display: flex; align-items: baseline">
      <h4 style="padding-right: 1em">{{ uploadResult.fileName }}</h4>
    </div>

    <button (click)="fileInput.click()" *ngIf="step == 2 && !uploadResult && !uploadStatus && fileInput" color="primary"
            mat-raised-button style="width: 150px">
      select File
    </button>
  </form>
</mat-dialog-content>

<dialog-form-actions>
  <button (click)="startUpload()" *ngIf="step==1" [disabled]="form.controls.type.invalid" color="primary"
          mat-raised-button>
    <mat-icon>forward</mat-icon>
    upload File
  </button>

  <button (click)="save()" *ngIf="step==2" [disabled]="form.invalid || !uploadResult" color="primary" mat-raised-button>
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button mat-button mat-dialog-close>Cancel</button>
</dialog-form-actions>
