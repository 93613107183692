import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MapSourceNamePipe} from './map-source-name.pipe';
import {ChainDocument, ReportDocument} from '../../model';

@Component({
  selector: 'app-report-product-documents',
  standalone: true,
  templateUrl: 'report-product-documents.component.html',
  styleUrls: ['report-product-documents.component.scss'],
  imports: [CommonModule, MatIconModule, MapSourceNamePipe]
})
export class ReportProductDocumentsComponent {
  @Input() preview!: { [p: string]: string };
  @Input() documents!: (ReportDocument | ChainDocument)[];
}
