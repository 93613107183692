import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {RiskCategory} from '../../model';

@Injectable({providedIn: 'root'})
export class RiskCategoryService extends _Service<RiskCategory> {
  constructor() {
    super('api/risk-category');
  }

  listAll = () => this.http.get<RiskCategory[]>(this.prefix);
}
