import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'dialog-form-actions',
  standalone: true,
  template: `
    <mat-dialog-actions class="dialog-form-action">
      <div class="spacer"></div>
      <ng-content></ng-content>
    </mat-dialog-actions>`,
  styles: [`
    .dialog-form-action {
      display: flex;
      gap: 1em;
      align-items: center;

      a, button {
        text-transform: uppercase;
      }

      .spacer {
        flex-grow: 1;
      }
    }
  `],
  imports: [MatDialogModule],
  encapsulation: ViewEncapsulation.None
})
export class DialogFormActionsComponent {
}
