import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AutotrimDirective, DialogFormActionsComponent} from '../../common';

@Component({
  selector: 'app-product-add-dialog',
  standalone: true,
  templateUrl: 'product-add-dialog.component.html',
  imports: [
    CommonModule, ReactiveFormsModule, MatDialogModule, DialogFormActionsComponent, MatToolbarModule, MatIconModule,
    MatFormFieldModule, MatButtonModule, MatInputModule, AutotrimDirective,
  ]
})
export class ProductAddDialogComponent {
  form = new FormBuilder().nonNullable.group({
    name: ['', Validators.required],
    hsCode: ['', Validators.required],
    notes: ['']
  });
}
