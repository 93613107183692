import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {Component, Inject} from '@angular/core';
import {CommonModule, formatDate} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DialogFormActionsComponent} from '../../common';
import {DocumentType} from '../../model';

@Component({
  selector: 'app-document-edit-dialog',
  standalone: true,
  templateUrl: 'document-edit-dialog.component.html',
  imports: [
    CommonModule, ReactiveFormsModule, MatDialogModule, DialogFormActionsComponent, MatToolbarModule, MatIconModule,
    MatButtonModule, MatInputModule, MatSelectModule, MatDatepickerModule,
  ]
})
export class DocumentEditDialogComponent {
  documentTypes: DocumentType[];
  form = new FormBuilder().nonNullable.group({
    title: ['', Validators.required],
    date: [new Date(), Validators.required],
    type: ['', Validators.required],
  });

  constructor(private dialogRef: MatDialogRef<DocumentEditDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.documentTypes = data.documentTypes;
    console.log("data.document", data.document.type.id)
    this.form.patchValue({...data.document, type: data.document.type.id});
  }

  save(): void {
    const result = this.form.value;
    this.dialogRef.close({
      ...result, date: formatDate(result.date!, 'YYYY-MM-dd', 'en-US'), type: {id: result.type}
    });
  }
}
