import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {AppId, LegalNorm, LegalNormArticle} from '../../model';
import {firstValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LegalNormService extends _Service<LegalNorm> {
  constructor() {
    super('api/legal-norm');
  }

  listAll = async () =>
    firstValueFrom(this.http.get<LegalNorm[]>('api/legal-norm'));
  detailArticle = async (legalNorm: AppId, article: AppId) =>
    firstValueFrom(this.http.get<LegalNormArticle>(`api/legal-norm/${legalNorm}/${article}`));
  deleteArticle = async (legalNorm: AppId, article: AppId) =>
    firstValueFrom(this.http.delete<LegalNormArticle>(`api/legal-norm/${legalNorm}/${article}`));
  createArticle = async (legalNorm: AppId, data: LegalNormArticle) =>
    firstValueFrom(this.http.post<LegalNormArticle>(`api/legal-norm/${legalNorm}`, data));
  updateArticle = async (legalNorm: AppId, article: AppId, data: LegalNormArticle) =>
    firstValueFrom(this.http.put<LegalNormArticle>(`api/legal-norm/${legalNorm}/${article}`, data));
}
