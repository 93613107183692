<header-actions style="margin:0.3em 0.5em">
  <h4 style="margin:0">Shape-File</h4>
  <button (click)="this.removeUpload();" *ngIf="uploadResult" mat-flat-button>remove shape</button>
  <button [mat-dialog-close]="uploadResult" color="primary" mat-flat-button>close</button>
</header-actions>

<mat-dialog-content style="max-height: unset; padding: 0">
  <div *ngIf="!uploadResult" style="padding:1em">
    <button (click)="startUpload()" *ngIf="!uploadStatus" color="primary" mat-raised-button>
      <mat-icon>forward</mat-icon>
      upload File
    </button>

    <div *ngIf="uploadStatus">
      <h4 mat-line>{{ fileName }}</h4>

      <div *ngIf="error; else uploading">
        <mat-error>{{ error }}</mat-error>
        <button (click)="removeUpload(); startUpload()" color="primary" mat-raised-button>ok</button>
      </div>
      <ng-template #uploading>
        <mat-label>Uploading ... {{ progress | number:'1.0-0' }}%</mat-label>
        <mat-progress-bar [value]="progress" mode="determinate"></mat-progress-bar>
        <button (click)="removeUpload()" mat-button>Cancel</button>
      </ng-template>
    </div>
  </div>
  <div #mapContainer style="height: 100%"></div>
</mat-dialog-content>
