import {Component, ElementRef, Input, OnDestroy, Optional, Self} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ControlValueAccessor, FormArray, FormControl, NgControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgForOf, NgIf} from '@angular/common';
import {Subject} from 'rxjs';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatTreeModule, MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {HasIdName} from '../model';

export interface IdNamePairChildren extends HasIdName {
  children?: HasIdName[];
}

@Component({
  selector: 'tree-selection[values]',
  standalone: true,
  template: `
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-nested-tree-node *matTreeNodeDef="let node" [disabled]="disabled">
        <div style="display:flex; flex-direction: row; align-items:center">
          <button *ngIf="node.children" [disabled]="disabled || isDisabled(node)" mat-icon-button matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          <mat-checkbox (change)="toggleSelection(node)" [checked]="isChecked(node)"
                        [disabled]="disabled || isDisabled(node)" [indeterminate]="isIndeterminate(node)"
                        [title]="node.name">
            {{ node.name }}
          </mat-checkbox>
        </div>
        <div *ngIf="treeControl.isExpanded(node)" style="margin-left:70px;">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  `,
  styles: [' mat-checkbox { overflow: hidden; white-space: nowrap; } '],
  providers: [{provide: MatFormFieldControl, useExisting: TreeSelectionInputComponent}],
  host: {
    '[id]': 'id',
    '(focusin)': 'onFocusIn()',
    '(focusout)': 'onFocusOut($event) //noinspection UnresolvedReference',
    'role': 'group'
  },
  imports: [NgForOf, NgIf, ReactiveFormsModule, MatCheckboxModule, MatTreeModule, MatIconModule, MatButtonModule],
})
export class TreeSelectionInputComponent implements ControlValueAccessor, MatFormFieldControl<HasIdName[]>, OnDestroy {
  static nextId = 0;
  controls = new FormArray<FormControl<boolean | null>>([]);
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'tree-selection';
  id = `tree-selection-${TreeSelectionInputComponent.nextId++}`;
  shouldLabelFloat = true;
  treeControl = new NestedTreeControl(({children}: IdNamePairChildren) => children);
  selection = new SelectionModel<HasIdName>(true);
  dataSource = new MatTreeNestedDataSource<IdNamePairChildren>();

  constructor(private _elementRef: ElementRef<HTMLElement>, @Optional() @Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  private _values: IdNamePairChildren[] = [];

  @Input()
  set values(values: IdNamePairChildren[]) {
    this.dataSource.data = values;
    this._values = values;
    this.controls = new FormArray(this._value.map(() => new FormControl(true)));
    this.stateChanges.next();
  }

  get empty() {
    return !!this.controls.value.length;
  }

  private _placeholder!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required || !!this.ngControl.control?.hasValidator(Validators.required);
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.controls.disable() : this.controls.enable();
    this.stateChanges.next();
  }

  _value: HasIdName[] = [];

  @Input()
  get value(): HasIdName[] | null {
    return this.controls.valid ? this._value : null;
  }

  set value(value: HasIdName[] | null) {
    this._value = [...(value || [])];
    this.controls = new FormArray(this._value.map(() => new FormControl(true)));
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return (this.controls.invalid || !!this.ngControl.control?.invalid) && this.touched;
  }

  setDescribedByIds = () => void 0;
  onContainerClick = () => void 0;
  onChange: (value: HasIdName[] | null) => void = () => void 0;
  onTouched = () => void 0;
  registerOnChange = (fn: any): void => this.onChange = fn;
  registerOnTouched = (fn: any): void => this.onTouched = fn;

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  writeValue(selection: HasIdName[] | null): void {
    const ids = (selection || []).map(({id}) => id);
    this.selection.clear();
    this.selection.setSelection(...(
      this._values
        .flatMap(({children}) => children || [])
        .filter(({id}) => ids.includes(id))
    ));
    this.value = selection;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleSelection(node: IdNamePairChildren): void {
    if (node.children && node.children.every(child => this.selection.isSelected(child))) {
      this.selection.deselect(...(node.children));
    } else if (node.children) {
      this.selection.select(...(node.children));
    } else {
      this.selection.toggle(node);
    }
    this.updateControl();
  }

  isDisabled = (node: IdNamePairChildren) => !!node.children && !node.children.length;

  isChecked = (node: IdNamePairChildren) => {
    if (node.children) {
      return node.children.length > 0 && node.children.every(child => this.selection.isSelected(child));
    }
    return this.selection.isSelected(node);
  };

  isIndeterminate = (node: IdNamePairChildren) =>
    !this.selection.isSelected(node) &&
    !node.children?.every(child => this.selection.isSelected(child)) &&
    node.children?.some(child => this.selection.isSelected(child));

  private updateControl() {
    this.value = this.selection.selected;
    this.onChange(this.value);
  }
}
