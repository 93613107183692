import {Component} from '@angular/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {BaseListComponent} from './base-list.component';

@Component({
  selector: 'app-paginator',
  standalone: true,
  template: `
    <mat-paginator #paginator
                   (page)="list.page.pageNumber=paginator.pageIndex;list.page.search()"
                   [length]="list.page.totalElements"
                   [pageIndex]="list.page.pageNumber"
                   [pageSize]="list.page.pageSize"
                   hidePageSize
    ></mat-paginator>
  `,
  imports: [MatPaginatorModule],
})
export class BaseListPaginatorComponent {
  constructor(public list: BaseListComponent<any, any>) {
  }
}
