import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  standalone: true,
  selector: 'input[appCustomAutofocus]'
})
export class CustomAutofocusDirective implements AfterViewInit {
  @Input() appCustomAutofocus!: boolean;

  constructor(private cdRef: ChangeDetectorRef, private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    if (this.appCustomAutofocus) {
      this.el.nativeElement.focus();
      this.el.nativeElement.scrollIntoView();
      this.cdRef.detectChanges();
    }
  }
}
