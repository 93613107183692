import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, Optional, Self} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ControlValueAccessor, FormBuilder, NgControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {AsyncPipe, NgForOf} from '@angular/common';
import {Subject} from 'rxjs';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {HasIdName} from '../model';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {filter, mergeMap, startWith} from 'rxjs/operators';

@Component({
  selector: 'chips-autocomplete[lookupFn]',
  standalone: true,
  template: `
    <mat-chip-grid #grid>
      <mat-chip-row (removed)="remove(r.value!)" *ngFor="let r of controls.controls">
        {{ r.value!.name }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input [formControl]="selectControl" [matAutocomplete]="auto" [matChipInputFor]="grid" matInput>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="add($event.option.value)"
                      [autoActiveFirstOption]="true">
      <mat-option *ngFor="let option of options | async" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  `,
  providers: [{provide: MatFormFieldControl, useExisting: ChipsAutocompleteInputComponent}],
  host: {
    '[id]': 'id',
    '(focusin)': 'onFocusIn()',
    '(focusout)': 'onFocusOut($event) //noinspection UnresolvedReference',
    'role': 'group'
  },
  imports: [NgForOf, AsyncPipe, ReactiveFormsModule, MatCheckboxModule, MatAutocompleteModule, MatChipsModule, MatIconModule, MatInputModule, MatOptionModule
  ],
})
export class ChipsAutocompleteInputComponent implements ControlValueAccessor, MatFormFieldControl<HasIdName[]>, OnDestroy {
  static nextId = 0;
  controls = new FormBuilder().nonNullable.array([] as HasIdName[]);
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'chips-autocomplete';
  id = `chips-autocomplete-${ChipsAutocompleteInputComponent.nextId++}`;
  shouldLabelFloat = true;
  selectControl = new FormBuilder().nonNullable.control('');

  constructor(private _elementRef: ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl,
              private changeDetectorRef: ChangeDetectorRef) {
    this.ngControl.valueAccessor = this;
  }

  private _lookupFn: (search: string, selected: string[]) => Promise<HasIdName[]> = async () => [];

  options = this.selectControl.valueChanges.pipe(
    startWith<string | any>(''),
    filter(value => value == null || typeof value == 'string'),
    mergeMap(async value => await this._lookupFn(value, (this.controls.value).map(({id}) => id)))
  );

  @Input()
  set lookupFn(lookupFn: (search: string, selected: string[]) => Promise<HasIdName[]>) {
    this._lookupFn = lookupFn;
    this.stateChanges.next();
  }

  get empty() {
    return !!this.value?.length;
  }

  private _placeholder!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required || !!this.ngControl.control?.hasValidator(Validators.required);
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.controls.disable() : this.controls.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): HasIdName[] | null {
    return this.controls.valid
      ? this.controls.value as HasIdName[]
      : null;
  }

  set value(value: HasIdName[] | null) {
    this.controls = new FormBuilder().nonNullable.array(value || []);
    this.selectControl.updateValueAndValidity();
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return (this.controls.invalid || !!this.ngControl.control?.invalid) && this.touched;
  }

  setDescribedByIds = () => void 0;
  onContainerClick = () => void 0;
  onChange = (value: HasIdName[] | null) => void 0;
  onTouched = () => void 0;

  add(entry: HasIdName) {
    let newValue = this.value || [];
    if (!newValue.some(({id}) => id == entry.id)) {
      newValue = [...newValue, entry];
      newValue = newValue.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.value = newValue;
    this.onChange(newValue);
    this.selectControl.setValue('', {emitEvent: true});
    this.changeDetectorRef.detectChanges();
  }

  remove(entry: HasIdName) {
    const newValue = [...(this.value || [])].filter(({id}) => id != entry.id);
    this.value = newValue;
    this.onChange(newValue);
    this.selectControl.setValue('', {emitEvent: true});
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange = (fn: any): void => this.onChange = fn;
  registerOnTouched = (fn: any): void => this.onTouched = fn;

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  writeValue(selection: HasIdName[] | null): void {
    this.value = selection;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
