import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalInfo} from '../../model';

@Injectable({providedIn: 'root'})
export class GlobalService {
  constructor(private http: HttpClient) {
  }

  info = () => this.http.get<GlobalInfo>('api/global');
}
