<mat-toolbar mat-dialog-title>
  Update Company-Owner for "{{ company.name }}"
</mat-toolbar>

<mat-dialog-content style="padding: 2em 1em">
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Owner</mat-label>
      <mat-select formControlName="owner">
        <mat-option *ngFor="let user of users" [value]="user.id">
          {{ user.email }}
        </mat-option>
      </mat-select>
      <mat-error>Required field</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<dialog-form-actions>
  <button mat-button mat-dialog-close>cancel</button>
  <button [disabled]="form.invalid || form.pristine" [mat-dialog-close]="form.value.owner" color="primary"
          mat-raised-button>
    <mat-icon>save</mat-icon>
    update Company-Owner
  </button>
</dialog-form-actions>
