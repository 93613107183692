<div *ngFor="let doc of documents">
  <img *ngIf="preview[doc.id]; else noImage" [alt]="doc.title"
       [attr.src]="'data:image/png;base64,'+preview[doc.id]"/>
  <ng-template #noImage>
    <mat-icon>web_asset_off</mat-icon>
  </ng-template>

  <h5>
    {{ doc | mapSourceName }} / {{ doc.type.name }}
  </h5>

  <div *ngIf="doc.pending" class="new">** new document **</div>
</div>
