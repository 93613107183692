import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, skip} from 'rxjs/operators';
import {Component, inject, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({template: '',})
export abstract class BaseListComponent<TYPE, FILTER extends { [key: string]: any }> implements OnInit {
  searchDelay = new BehaviorSubject<any>(undefined);
  list!: TYPE[];
  page!: { search: () => void, totalElements: number, pageSize: number, pageNumber: number };
  filter: FILTER = {} as any;
  route = inject(ActivatedRoute);
  router = inject(Router);
  toastr = inject(ToastrService);

  public updateFilter(): void {
    this.searchDelay.next(undefined);
  }

  public resetFilter(): void {
    this.filter = {} as any;
    this.search();
  }

  public async search() {
    const queryParams: { [key: string]: any } = {...this.filter, page: this.page.pageNumber};
    for (const key in queryParams) {
      if (queryParams[key] === '' || queryParams[key] === 0 || queryParams[key] === false) {
        queryParams[key] = undefined;
      }
    }
    await this.router.navigate([], {relativeTo: this.route, queryParams});
  }

  ngOnInit(): void {
    this.searchDelay
      .pipe(skip(1), debounceTime(500))
      .subscribe(() => this.search());

    this.route.queryParams.subscribe((params) => {
      this.filter = {...(params || {})} as FILTER;
    });

    this.route.data.subscribe(({list}) => {
      this.list = list.content;
      this.page = {...list, search: () => this.search()};
    });
  }
}
