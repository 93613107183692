<mat-toolbar mat-dialog-title>
  <div></div>
  <div style="flex-grow: 1"></div>
  <mat-icon mat-dialog-close>close</mat-icon>
</mat-toolbar>

<mat-dialog-content>
  <iframe (load)="loaded($event)" *ngIf="url" [frameBorder]="0" [height]="height" [src]="url" allowfullscreen
          style="width: 100%;"></iframe>
</mat-dialog-content>
