import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {ImportResult} from '../../model';

@Injectable({providedIn: 'root'})
export class ApiService {
  private http = inject(HttpClient);

  adminUpdateLocations = () =>
    firstValueFrom(this.http.post<any>('api/admin/import-locations', {}));
  adminWipe = () =>
    firstValueFrom(this.http.post<ImportResult[]>('api/admin/wipe', {}));
  adminExec = (cypher: string) =>
    firstValueFrom(this.http.post<ImportResult[]>('api/admin/exec', {cypher}));
  adminExecDownload = (cypher: string) =>
      firstValueFrom(this.http.post('api/admin/exec-download',
          {cypher},
          {responseType: 'blob', headers: {Accept: 'application/vnd.ms-excel'}}
      ));
  adminDump = () =>
    firstValueFrom(this.http.get('api/admin/dump/database-full', {responseType: 'text'}));
  adminTesting = (url: string) =>
    firstValueFrom(this.http.post<any>('api/admin/gpt-test', {url}));
  loadShape = (id: string): Promise<any> =>
    firstValueFrom(this.http.get<any>(`api/geojson/user-shape/${id}`));
  loadShapeShort = (id: string): Promise<any> =>
    firstValueFrom(this.http.get<any>(`api/geojson/${encodeURI(id)}`));
  exploreLandLink = (id: string): Promise<any> =>
    firstValueFrom(this.http.get<{ url: string }>(`api/geojson/explore-land/${encodeURI(id)}`));
}
