<mat-toolbar mat-dialog-title>
  Edit Document
</mat-toolbar>

<mat-dialog-content style="padding: 2em 1em">
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input formControlName="title" matInput>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Document Date</mat-label>
      <input [matDatepicker]="documentDatePicker" formControlName="date" matInput placeholder="MM/DD/YYYY">
      <mat-datepicker-toggle [for]="documentDatePicker" matIconSuffix></mat-datepicker-toggle>
      <mat-datepicker #documentDatePicker></mat-datepicker>
      <mat-error>Required field</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of documentTypes" [value]="type.id">{{ type.name }}</mat-option>
      </mat-select>
      <mat-error>Required field</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<dialog-form-actions>
  <button mat-button mat-dialog-close>cancel</button>
  <button (click)="save()" [disabled]="form.invalid || form.pristine" color="primary" mat-raised-button>
    <mat-icon>save</mat-icon>
    save Document
  </button>
</dialog-form-actions>


