import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'header-actions',
  standalone: true,
  template: `
    <div class="header">
      <ng-content select="h1,h2,h3,h4,dl,div,form"></ng-content>
      <div class="spacer"></div>
      <ng-content select="a,button"></ng-content>
    </div>
  `,
  styles: [`
    div.header {
      & > h1, & > h2, & > h3, & > h4, & > dl, & > div, & > form {
        flex-grow: 1;
      }

      & > dl {
        margin: 0 0 20px;
      }

      display: flex;
      gap: 1em;
      align-items: center;

      button {
        text-transform: uppercase;
      }

      .spacer {
        flex-grow: 1;
      }
    }
  `],
  encapsulation: ViewEncapsulation.None
})
export class HeaderActionsComponent {
}
