<mat-toolbar mat-dialog-title>
  Form changed
</mat-toolbar>

<div mat-dialog-content style="padding: 2em 1em">
  Discard or continue edit?
</div>

<dialog-form-actions>
  <button [mat-dialog-close]="true" color="primary" mat-raised-button>discard</button>
  <button [mat-dialog-close]="false" mat-raised-button>edit</button>
</dialog-form-actions>
