import {Directive} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  standalone: true,
  selector: '[upperCase]'
})
export class UppercaseDirective {

  constructor(ngControl: NgControl) {
    const valueAccessor = ngControl.valueAccessor!;
    const {registerOnChange} = valueAccessor;
    valueAccessor.registerOnChange = (fn: any) =>
      registerOnChange.call(valueAccessor, (value: any) => {
        const newValue = typeof value === 'string' ? value.toUpperCase() : value;
        if (newValue !== value) {
          ngControl.control?.patchValue(newValue, {emitModelToViewChange: true});
        }
        return fn(newValue);
      });
  }
}
