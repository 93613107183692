<mat-toolbar mat-dialog-title>
  <div>search News</div>
  <mat-chip-listbox>
    <mat-chip *ngFor="let l of data.locations" class="location">
      <mat-icon>language</mat-icon>
      {{ l.name }}
    </mat-chip>
    <mat-chip *ngFor="let r of data.risks" class="risk">
      <mat-icon>bolt</mat-icon>
      {{ r.name }}
    </mat-chip>
  </mat-chip-listbox>
  <div style="flex-grow: 1"></div>
  <mat-icon mat-dialog-close>close</mat-icon>
</mat-toolbar>

<mat-dialog-content>
  <a tabindex="0"></a>

  <h1 *ngIf="page.content.length == 0">No news found</h1>

  <ul>
    <li *ngFor="let e of page.content">
      <div>
        <div>
          <img *ngIf="e.imageData; else noImage" [attr.src]="e.imageData" alt="preview" width="130"/>
          <ng-template #noImage>
            <mat-icon>web_asset_off</mat-icon>
          </ng-template>
        </div>
        <div>
          {{ e.rating }}
        </div>
      </div>
      <div>
        <a [href]="e.source" target="_blank">
          <mat-icon>open_in_new</mat-icon>
          <h3>{{ e.title }}</h3>
        </a>
        <div class="clip" overflowGradient>{{ e.description }}</div>
        <mat-chip-listbox>
          <mat-chip *ngFor="let l of e.locations" class="location">
            <mat-icon>language</mat-icon>
            {{ l.name }}
          </mat-chip>
          <mat-chip *ngFor="let r of e.risks" class="risk">
            <mat-icon>bolt</mat-icon>
            {{ r.name }}
          </mat-chip>
        </mat-chip-listbox>
      </div>
    </li>
  </ul>
  <app-paginator/>
</mat-dialog-content>
