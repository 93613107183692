import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {AppId, HasIdName, Team, User} from '../../model';
import {AuthUser} from '../auth/app-auth.service';
import {firstValueFrom} from 'rxjs';

export interface TokenResponse {
  token: AppId;
}

export interface ErrorResponse {
  error: boolean;
}

export const isErrorResponse = (response: any): response is ErrorResponse => 'error' in response && response.error;

@Injectable({providedIn: 'root'})
export class UserService extends _Service<User> {
  constructor() {
    super('api/user');
  }

  acceptTerms = async (acceptedTerms: Boolean, name: string) =>
    firstValueFrom(this.http.put<any>('api/auth/profile-terms', {acceptedTerms, name}));
  profile = async () =>
    firstValueFrom(this.http.get<User>('api/auth/profile'));
  updateProfile = async (data: User) =>
    firstValueFrom(this.http.put<User>('api/auth/profile', data));
  team = async () =>
    firstValueFrom(this.http.get<Team>('api/user/team'));
  fetchTeamToken = async () =>
    firstValueFrom(this.http.get<TokenResponse>('api/user/team/token'));
  revokeTeamToken = async () =>
    firstValueFrom(this.http.delete<TokenResponse>('api/user/team/token'));
  migrateTeamMember = async (token: string) =>
    firstValueFrom(this.http.post<Team | ErrorResponse>('api/user/team', {token}));
  inviteTeamMember = async (name: string, email: string) =>
    firstValueFrom(this.http.post<Team | ErrorResponse>('api/user/team/invite', {name, email}));
  removeTeamMember = async (user: User) =>
    firstValueFrom(this.http.delete<Team>(`api/user/team/${user.id}`));
  updateTeam = async (team: Team) =>
    firstValueFrom(this.http.put<Team>('api/user/team', team));
  impersonate = async (user: User) =>
    firstValueFrom(this.http.get<AuthUser>(`api/user/impersonate/${user.id}`));
  all = async () =>
    firstValueFrom(this.http.get<HasIdName[]>(`api/user/all`));
}
