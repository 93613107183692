import {Injectable} from '@angular/core';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {firstValueFrom, mergeMap, of} from 'rxjs';
import {Company, CompanySite, Document} from '../../model';
import {_Service} from './_service';
import {buildQuery} from '../../util';

@Injectable({providedIn: 'root'})
export class DocumentService extends _Service<Document> {
  constructor() {
    super('api/document');
  }

  upload(file: File, type: string, title: string, onProgress: (progress: number) => void) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', type);
    formData.append('title', title);
    return this.http.post<Document>(
      'api/document', formData, {reportProgress: true, observe: 'events'})
      .pipe(
        mergeMap((event) => {
          if (event.type === HttpEventType.UploadProgress && !!event.total) {
            onProgress(100 * event.loaded / event.total);
          }
          if (event instanceof HttpResponse) {
            return of(event.body as Document);
          }
          return of();
        }));
  }

  download = async (id: string, inviteCode?: string) =>
    firstValueFrom(this.http.get(
      `api/document/${id}/download${inviteCode ? `?inviteCode=${inviteCode}` : ''}`,
      {observe: 'response', responseType: 'blob'}));

  preview = async (id: string, inviteCode?: string) =>
    firstValueFrom(this.http.get<{ data: string, type: string }>(
      `api/document/${id}/preview` + buildQuery({inviteCode})
    ));

  previewList = async (ids: string[]) =>
    firstValueFrom(this.http.post<{ [key: string]: string }>(`api/document/preview`, ids));

  addCompanyDocument = async ({id}: Company, document: Document) =>
    firstValueFrom(this.http.post(`api/company/${id}/document`, {id: document.id}));

  addCompanySiteDocument = async ({id}: CompanySite, document: Document) =>
    firstValueFrom(this.http.post(`api/site/${id}/document`, {id: document.id}));
}
