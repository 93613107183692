<mat-toolbar mat-dialog-title>
  <div>related documents</div>
  <div style="flex-grow: 1"></div>
  <mat-icon mat-dialog-close>close</mat-icon>
</mat-toolbar>

<mat-dialog-content>
  <a tabindex="0"></a>
  <app-report-product-documents [documents]="documents" [preview]="preview"></app-report-product-documents>
</mat-dialog-content>
