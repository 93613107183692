import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {AppId, HasId, Location} from '../../model';
import {firstValueFrom, mergeMap, Observable, of} from 'rxjs';
import {buildQuery} from '../../util';
import {HttpEventType, HttpResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LocationService extends _Service<Location> {
  constructor() {
    super('api/location');
  }

  listAll = async (q: string) =>
    q == '' ? [] : firstValueFrom(this.http.get<Location[]>('api/location/all' + buildQuery({q})));

  listById = async (ids: string[]) =>
    ids.length == 0 ? [] : firstValueFrom(this.http.get<Location[]>('api/location/id' + buildQuery({ids})));

  uploadShx(file: File, onProgress: (progress: number) => void): Observable<UploadResult> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<HasId & { center: { lng: number, lat: number }[] }>(
      'api/location/shx-upload', formData, {reportProgress: true, observe: 'events'})
      .pipe(
        mergeMap((event) => {
          if (event.type === HttpEventType.UploadProgress && !!event.total) {
            onProgress(100 * event.loaded / event.total);
          }
          if (event instanceof HttpResponse) {
            return of(event.body as any);
          }
          return of();
        }));
  }

  deleteShx = async (id: AppId) =>
    await firstValueFrom(this.http.delete(`api/location/shx-upload/${id}`));
}

export interface UploadResult extends HasId {
  center: { lng: number, lat: number };
}
