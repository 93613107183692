import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {NewsArticle} from '../../model';
import {firstValueFrom} from 'rxjs';
import {buildQuery} from '../../util';

@Injectable({providedIn: 'root'})
export class NewsArticleService extends _Service<NewsArticle> {
  constructor() {
    super('api/news-article');
  }

  count = async (data: { [key: string]: string[] }) =>
    firstValueFrom(this.http.post<{ [key: string]: [number, Date] }>('api/news-article/count', data));

  previewImage = async (url: string) =>
    firstValueFrom(this.http.get('api/news-article/preview-image' + buildQuery({url}), {responseType: 'text'}));
}
