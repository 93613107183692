import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {Component, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {Observable, startWith} from 'rxjs';
import {map} from 'rxjs/operators';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatOptionModule} from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {DialogFormActionsComponent} from '../../common';
import {Product, SupplierProduct} from '../../model';

@Component({
  selector: 'app-product-select-supplier-dialog',
  standalone: true,
  templateUrl: 'product-select-supplier-dialog.component.html',
  imports: [
    CommonModule, ReactiveFormsModule, MatDialogModule, DialogFormActionsComponent, MatToolbarModule, MatIconModule,
    MatFormFieldModule, MatButtonModule, MatInputModule, MatAutocompleteModule, MatOptionModule,
  ]
})
export class ProductSelectSupplierDialogComponent {
  form = new FormBuilder().nonNullable.group({
    product: [null as SupplierProduct | null, Validators.required],
  });
  entries: Observable<SupplierProduct[]>;
  product: Product;
  entry?: SupplierProduct;

  constructor(@Inject(MAT_DIALOG_DATA) data: { product: Product, suppliers: SupplierProduct[] }) {
    this.product = data.product;
    const suppliers = data.suppliers;
    this.entries = this.form.controls.product.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : '')),
      map(value => {
        if (value) {
          const filterValue = value.toLowerCase();
          return suppliers.filter(({name, product}) =>
            name?.toLowerCase().includes(filterValue) || product.name.toLowerCase().includes(filterValue));
        } else {
          return suppliers.slice();
        }
      })
    );
  }

  displayEntry(entry: SupplierProduct) {
    if (!entry) {
      return '';
    }
    return `${entry.product.name} (${entry.name})`;
  }
}
